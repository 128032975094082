import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-content-request',
  templateUrl: './content-request.component.html',
  styleUrls: ['./content-request.component.scss']
})
export class ContentRequestComponent implements OnInit {
  router: any;

  constructor() { }

  ngOnInit(): void {

  }
  gotorequest()
  {
    this.router.navigateByUrl('request');
  }

}
